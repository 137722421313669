import React from 'react';
import {
  createFragmentContainer,
  graphql,
} from 'react-relay';
import PropTypes from 'prop-types';
import Helmet from '~/components/page/Helmet';
import { Link } from 'found';
import { Row, Col } from 'antd';
import { get } from 'lodash';
import MediaQuery from 'react-responsive';
import GA4 from '~/ga4';
import './index.css';
import FeatureList from './FeatureList';

const AU_BRANDS = {
  "makita": {
    name: "Makita",
    color: "#00909E",
  },
  "milwaukee": {
    name: "Milwaukee",
    color: "#ED2340",
  },
  "dewalt": {
    name: "DeWalt",
    color: "#FBB73B",
  },
  "hikoki": {
    name: "HiKOKI",
    color: "#199448",
  },
  "bosch": {
    name: "Bosch",
    color: "#00335D",
  },
  "daytona": {
    name: "Daytona",
    background: "daytona_bg",
  },
};

const NZ_BRANDS = {
  "makita": {
    name: "Makita",
    color: "#00909E",
  },
  "milwaukee": {
    name: "Milwaukee",
    color: "#ED2340",
  },
  "dewalt": {
    name: "DeWalt",
    color: "#FBB73B",
  },
  "bosch": {
    name: "Bosch",
    color: "#00335D",
  },
  "daytona": {
    name: "Daytona",
    background: "daytona_bg",
  },
  "xcelarc": {
    name: "Xcelarc",
    background: "xcelarc_bg",
  },
};

let brands = AU_BRANDS;

if (process.env.COUNTRY === 'NZ') {
  brands = NZ_BRANDS;
}

class Home extends React.Component {
  static propTypes = {
    viewer: PropTypes.shape({
      cmsCarouselImages: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      cmsBanners: PropTypes.shape({
        edges: PropTypes.arrayOf(PropTypes.object),
      }),
      cmsPage: PropTypes.shape({
        title: PropTypes.string,
        metaDescription: PropTypes.string,
      }),
    }).isRequired,
    initBanners: PropTypes.func,
    router: PropTypes.shape({
    }),
  }

  static defaultProps = {
    initBanners: null,
    router: null,
  }

  constructor(props) {
    super(props);

    const banners = get(props.viewer, 'cmsCarouselImages.edges', []);

    if (props.initBanners) {
      props.initBanners(banners);
    }
  }

  onClick = (k, index) => {
    const title = `Home page big brands ${k.name}`;
    const v = {
      id: null,
      title,
    };

    GA4.selectPromotion(v, index);
  }

  render() {
    const { router, viewer } = this.props;
    const homePage = viewer.cmsPage;
    const banners = get(viewer, 'cmsBanners.edges', []);

    return (
      <div>
        {homePage && (
          <Helmet title={homePage.title} >
            {homePage.metaDescription && (
            <meta name="description" content={homePage.metaDescription} />
          )}
          </Helmet>
        )}
        <Row className="bigbrands" style={{padding: '10px'}}>
          {Object.keys(brands).map((k, index) => (
            <Col key={k} xs={12} sm={8} md={4}>
              <Link
                onClick={() => { this.onClick(brands[k], index); }}
                title={brands[k].name}
                to={`/category/by-brand/${k}`}
                style={{
                  backgroundColor: brands[k].color,
                  backgroundImage: brands[k].background ? `url(/assets/images/home/big_brands/${brands[k].background}.png)` : null,
                  backgroundSize: 'cover',
                }}
              >
                <img
                  className="img-fluid click-state"
                  title={brands[k].name}
                  src={`/assets/images/home/big_brands/${k}_logo.png`}
                  alt={brands[k].name}
                  style={{
                    padding: '5px 0',
                  }}
                />

                <MediaQuery minWidth={769}>
                  <img
                    className="img-fluid click-state"
                    title={brands[k].name}
                    src={`/assets/images/home/big_brands/${k}.png`}
                    alt={brands[k].name}
                    style={{
                      padding: '0 6px',
                    }}
                  />
                </MediaQuery>
              </Link>
            </Col>
          ))}
        </Row>

        {banners.length > 0 && (
        <Row>
          {banners.map((edge) => {
            const b = edge.node;
            if (b.homeBig || b.homeSmall) {
              return (
                <Col key={b.id}>
                  <a href={b.link}>
                    <MediaQuery minWidth={769}>
                      <img alt={b.title} className="img-fluid " src={b.homeBig} />
                    </MediaQuery>
                    <MediaQuery maxWidth={768}>
                      <img alt={b.title} className="img-fluid" src={b.homeSmall} />
                    </MediaQuery>
                  </a>
                </Col>
              );
            }

            return null;
          })}
        </Row>
        )}

        <FeatureList viewer={viewer} router={router} />
      </div>
    );
  }
}

export default createFragmentContainer(Home, {
  viewer: graphql`
    fragment home_viewer on Customer {
      ...FeatureList_viewer
      cmsPage(urlSlug: "home") {
        title
        urlSlug
        content
        metaDescription
      }
      cmsBanners(first: 999) {
        edges {
          node {
            id
            title
            link
            homeBig
            homeSmall
          }
        }
      }
      cmsCarouselImages(first: 999) {
        edges {
          node {
            id
            title
            link
            main1
            main2
            bg1
            bg2
          }
        }
      }
    }
  `,
});
